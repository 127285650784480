"use strict";
import LazyLoad from 'vanilla-lazyload';
import 'whatwg-fetch'
import AOS, { init } from 'aos';
import Glide from '@glidejs/glide';
import Utils from './Helpers/Utils';

const BODY = document.body;

const initScrollListeners = () => {
    let currentScrollY = 0;
    let ticking = false;
    const header = document.querySelector('.header-primary');

    const triggerClassOnPosition = (element, pos, targetPos) => {
        const hasClass = !!element && element.classList.contains('is-sticky');
        const offset = 130;

        if (pos > targetPos) {
            if (element && !hasClass) element.classList.add('is-sticky');
        } else if (element && hasClass && pos < targetPos - offset) {
            element.classList.remove('is-sticky');
        }
    };

    const triggerHeaderAnimation = (pos) => {
        const width = window.innerWidth;

        if (width < 991) return;
        triggerClassOnPosition(header, pos, 600);
    };

    // Lean, mean, animations
    // https://www.html5rocks.com/en/tutorials/speed/animations/
    window.addEventListener('scroll', (e) => {
        currentScrollY = window.scrollY;

        if (!ticking) {
            window.requestAnimationFrame(() => {
                triggerHeaderAnimation(currentScrollY);

                ticking = false;
            });
        }

        ticking = true;
    });
};

/**
 * ------------------------------------------------------------------------
 * MOBILE TOGGLE
 * ------------------------------------------------------------------------
 */

const initMobileMenu = () => {
    const navPanel = document.querySelector('nav.panel');
    const navToggle = document.querySelector('.js-panel-show');
    let modal = null;

    navToggle.addEventListener('click', (e) => {
        e.preventDefault();

        modal = document.createElement('div');
        modal.classList.add('modal-backdrop', 'js-panel-close', 'fade', 'show');

        BODY.appendChild(modal);
        BODY.classList.add('modal-open');

        navPanel.classList.add('show');
    });

    const panelClose = (e) => {
        if (e.target.closest('.js-panel-close')) {
            e.preventDefault();

            navPanel.classList.remove('show');
            BODY.classList.remove('modal-open');

            BODY.removeChild(modal);
        }
    };

    document.addEventListener('click', panelClose);
};

const initImages = () => {
	const getImages = new LazyLoad();
}

const initClamping = () => {
    const clampArea = document.querySelector('.clamp-area');
    const clampButton = document.querySelector('.clamp-button');

    const clampListener = (e) => {
        const clampArea = e.target.closest('.clamp-area');

        if (clampArea) {
            e.preventDefault();
            clampArea.classList.add('show-all');
        }
    };

    document.addEventListener('click', clampListener);
};

const initCarousel = () => {
    const selector = '[data-toggle="carousel"]';
    const carousels = document.querySelectorAll(selector);

    if (carousels) {
        carousels.forEach(carousel => {
            new Glide(carousel, {
                type: 'slider',
                autoplay: false,
                perView: 1,
                gap: 12,
                startAt: 0,
                rewind: false,
                breakpoints: {
                    768: {
                    },
                },
                classes: {
                    direction: {
                        ltr: 'glide-ltr',
                        rtl: 'glide-rtl',
                    },
                    slider: 'glide-slider',
                    carousel: 'glide-carousel',
                    swipeable: 'glide-swipeable',
                    dragging: 'glide-dragging',
                    cloneSlide: 'glide-slide-clone',
                    activeNav: 'glide-bullet-active',
                    activeSlide: 'glide-slide-active',
                    disabledArrow: 'glide-arrow-disabled',
                    nav: {
                        active: 'glide-bullet-active',
                    }
                },
            }).mount();
        });
    }
};

const initTracking = () => {
    // Parse data and return as object
    const handleData = (element) => (
        {
            props: {
                target: element.getAttribute('href'),
                origin: element.dataset.origin,
            }
        }
    );

    // Define handler so we don't have to inline the function
    const handleDefaultTrackingClicks = (event) => {
        // Check if we should track this click
        const element = event.target.closest('[data-track]');

        if (element) {
            const middle = event.type === "auxclick" && event.which === 2;
            const click = event.type === "click";
            const data = element.dataset;

            if (middle || click) {
                if (typeof plausible === 'function') {
                    plausible(data.track, handleData(element));
                }
            }

            if (event.which === 13 || event.type === 'click') {
                event.preventDefault();
                const goto = element.href;

                const forceBlank = element.target && element.target === '_blank';

                setTimeout(() => {
                    if (event.metaKey || event.ctrlKey || forceBlank) {
                        window.open(goto, '_blank');
                    } else {
                        window.location = goto;
                    }
                }, 50)
            }
        }
    };

    // Add click listener
    document.addEventListener('click', handleDefaultTrackingClicks);
    document.addEventListener('auxclick', handleDefaultTrackingClicks);
}

const renderScrollPixels = (thresholds) => {
    const thresholdsFragment = document.createDocumentFragment();

    // Create a pixel for each threshold
    thresholds.forEach(threshold => {
        const thresholdPixel = document.createElement('div');
        thresholdPixel.classList.add('threshold-pixel');
        thresholdPixel.dataset.threshold = threshold;
        thresholdPixel.style.top = `${threshold}%`;
        thresholdsFragment.appendChild(thresholdPixel);
    });

    BODY.appendChild(thresholdsFragment);
}

const initScrollTracking = () => {
    const thresholds = [25, 50, 75, 100];

    renderScrollPixels(thresholds);
    initScrollIntersectionObserver();
}

const initScrollIntersectionObserver = () => {
    const items = [].slice.call(document.querySelectorAll('.threshold-pixel'));

    const itemsObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                const item = entry.target;
                const path = window.location.pathname;
                const depth = `${item.dataset.threshold}%`;

                const trackData = {
                    props: {
                        origin: path,
                        depth: depth,
                        tag: `${path} - ${depth}`,
                        time: `${(performance.now() / 1000).toFixed(2)}s`,
                    }
                }

                if (typeof plausible === 'function') {
                    plausible('Scroll', trackData);
                }
                itemsObserver.unobserve(item);
            }
        });
    }, { threshold: [0, 1] });

    items.forEach((item) => {
        itemsObserver.observe(item);
    });
}

// const initHeaderObserver = () => {
//     const header = document.querySelector('.header-primary');

//     const headerObserver = new IntersectionObserver((entries) => {
//         if (!entries[0].isIntersecting) {
//             header.classList.add('enabled')
//           } else {
//             header.classList.remove('enabled')
//           }
//     });

//     headerObserver.observe(header);
// }

/**
 * ------------------------------------------------------------------------
 * Animations
 * ------------------------------------------------------------------------
 */

const initAnimations = () => {
    if((window.matchMedia('(prefers-reduced-motion: reduce)').matches)) {
        AOS.init({
            disable: true
        });
    } else {
        AOS.init({
            disable: 'mobile',
            once: true,
            delay: 100,
        });
    }
};

Utils.domLoaded(() => {
	initImages();
    initAnimations();
    initMobileMenu();
    initScrollListeners();
    initClamping();
    initCarousel();
    initTracking();
    initScrollTracking();

    document.addEventListener('afterBlitzInject', function (event) {
        const parent = event.detail.element.parentNode;
        parent.classList.remove('is--loading');

        initImages();
    });
});

Utils.windowLoaded(() => {
});
